import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

// Async thunk to fetch campaigns 
export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async ({ filter, sort, client, tag, search, page, limit, type = 'campaign', includeTags, includeClients }, { rejectWithValue }) => {
    try {
      const params = {
        filter,
        sort,
        tag,
        client,
        search,
        page,
        limit,
        type,
        includeTags, includeClients
      };

      // Only include params that are defined (not null or undefined)
      const queryParams = Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .reduce((obj, key) => {
          obj[key] = params[key];
          return obj;
        }, {});

      const resp = await axiosInstance.get('/campaigns', {
        params: queryParams,
      });

      if (resp && resp.data) {
        return resp.data;
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update a campaign
export const createCampaign = createAsyncThunk(
  'campaigns/updateCampaign',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/campaigns`, data);
      if (response && response.data) {
        // console.log(response.data, "response.data")
        return response.data.data;
      }
    } catch (error) {
      console.error("Error updating campaign:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update a campaign
export const updateCampaign = createAsyncThunk(
  'campaigns/updateCampaign',
  async ({ id, updates }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/campaigns/${id}`, updates);
      if (response && response.data) {
        // console.log(response.data, "response.data")
        return response.data.data;
      }
    } catch (error) {
      console.error("Error updating campaign:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to delete a single campaign
export const deleteCampaign = createAsyncThunk(
  'campaigns/deleteCampaign',
  async (id, { rejectWithValue }) => {
    try {
      const resp = await axiosInstance.delete(`/campaigns/${id}`);
      const { success } = resp.data;
      if (success) {
        return true; // Return the ID of the deleted campaign
      }
    } catch (error) {
      console.error("Error deleting campaign:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to delete multiple campaigns
export const deleteMultipleCampaigns = createAsyncThunk(
  'campaigns/deleteMultipleCampaigns',
  async (ids, { rejectWithValue }) => {

    try {
      const resp = await axiosInstance.delete('/campaigns/batch-delete', {
        data: { ids }
      });
      const { success } = resp.data;
      if (success) {
        return ids;
      }
    } catch (error) {
      console.error("Error deleting campaigns:", error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const cloneCampaign = createAsyncThunk(
  'campaigns/cloneCampaign',
  async (campaignId, { rejectWithValue }) => {
    try {
      // Clone the campaign
      const cloneResponse = await axiosInstance.post(`/campaigns/clone/${campaignId}`);
      const clonedCampaign = cloneResponse.data.data;
      return clonedCampaign;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchCampaignDetails = createAsyncThunk(
  'campaigns/fetchCampaignDetails',
  async ({ campaignId, stats, endDate, startDate }, { rejectWithValue }) => {
    // console.log(endDate, startDate, "endDate,startDate")
    try {
      const response = await axiosInstance.get(`/campaigns/${campaignId}`, {
        params: {
          includeStats: stats,
          endDate: endDate,
          startDate: startDate
        },
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const launchCampaign = createAsyncThunk(
  'campaigns/launchCampaign',
  async (campaignId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/campaigns/${campaignId}/launch`);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const assingLeads = createAsyncThunk(
  'campaigns/assingLeads',
  async ({ campaignId, leads, mappings }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/campaigns/${campaignId}/assignLeads`, { campaignId, leads, mappings });
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const getLeads = createAsyncThunk(
  'campaigns/getLeads',
  async ({ filters, sort }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/leads`, {
        params: {
          ...filters,
          sort
        }
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getLeadDetails = createAsyncThunk(
  'campaigns/getLead',
  async ({ id, includesActivity = false }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/lead/${id}`, {
        params: {
          includesActivity
        }
      });
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const updateLead = createAsyncThunk(
  'campaigns/getLead',
  async ({ id, updates }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/lead/${id}`, updates);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const saveAsTemplate = createAsyncThunk(
  'template/save',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/template/custom`, data);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getCustomTemplates = createAsyncThunk(
  'template/save',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/get-templates/custom`);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching client IDs:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getGlobalStats = createAsyncThunk(
  'template/save',
  async ({campaignIds, range}, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/campaigns-stats`,{campaignIds, range});
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching vampaigns stats:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
const campaignSlice = createSlice({
  name: 'campaigns',
  initialState: {
    campaigns: [],
    tags: [],
    loading: false,
    error: null,
    message: null,
    clientIds: []
  },
  reducers: {},
  extraReducers: (builder) => {
    // Fetch Campaigns
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.campaigns;
        state.tags = action.payload.tags;
        state.clientIds = action.payload.clientIds;


        state.loading = false;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch campaigns';
      })
      // Update Campaign
      .addCase(updateCampaign.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.campaigns = state.campaigns.map(campaign =>
          campaign._id === action.payload._id ? action.payload : campaign
        );
        state.loading = false;
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update campaign';
      })

      // Delete Campaign
      .addCase(deleteCampaign.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.campaigns = state.campaigns.filter(campaign => campaign._id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete campaign';
      })

      // Delete Multiple Campaigns
      .addCase(deleteMultipleCampaigns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMultipleCampaigns.fulfilled, (state, action) => {
        state.campaigns = state.campaigns.filter(campaign => !action.payload.includes(campaign._id));
        state.loading = false;
      })
      .addCase(deleteMultipleCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete campaigns';
      });
  },
});

export default campaignSlice.reducer;
