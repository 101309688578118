import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Frame1Image, Frame2Image, Frame3Image } from '../../assets';

const DemoCarousel = () => {
  const slides = [
    {
      image: Frame1Image,
      alt: "Dynamic Analytics Dashboard",
      description: "Empower your decisions with dynamic, real-time analytics."
    },
    {
      image: Frame2Image,
      alt: "Email Management System",
      description: "Streamline your email workflow with intelligent filters."
    },
    {
      image: Frame3Image,
      alt: "Team Collaboration Platform",
      description: "Enhance your team's efficiency with real-time insights."
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0); // State to track current slide

  // Retrieve the slide index from localStorage on component mount
  useEffect(() => {
    const savedSlide = localStorage.getItem('currentSlide');
    if (savedSlide) {
      setCurrentSlide(parseInt(savedSlide, 10));
    }
  }, []);

  // Save the current slide index to localStorage on slide change
  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    localStorage.setItem('currentSlide', index);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    initialSlide: currentSlide, // Start from the saved slide
    beforeChange: (oldIndex, newIndex) => handleSlideChange(newIndex), // Track slide changes
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className="slide-content">
          <div className="image-container">
            <img
              src={slide.image}
              alt={slide.alt}
              className="w-full md:w-[500px] h-[500px] object-contain"
            />
          </div>
          <div className="text-content flex flex-col px-4 md:px-0 justify-end">
            <h2 className="text-2xl font-medium text-white mt-12 mb-4">
              {slide.alt}
            </h2>
            <p className="text-white text-start text-sm font-medium mb-12">
              {slide.description}
            </p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default DemoCarousel;
