import React from 'react';
import { useNavigate } from 'react-router-dom';
import { backbutton } from '../../assets/index';

const BackButton = () => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate(-1); 
  };

  return (
    <div
      className="absolute z-[50] top-8 left-10   cursor-pointer"
      onClick={handleClick}
    >
      <img src={backbutton} alt="Back Button"/>
    </div>
  );
};

export default BackButton;
