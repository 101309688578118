import React from 'react';
import { Menu } from '@mui/material';

const RoleDropdown = ({ selectedOption, setSelectedOption, toggleDropdown, anchorEl }) => {
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    toggleDropdown();
  };
console.log(anchorEl, 'anchorEl')
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={toggleDropdown}
      anchorOrigin={{
        vertical: 'bottom', // Anchor the menu at the bottom of the element
        horizontal: 'right', // Align to the right of the element
      }}
      transformOrigin={{
        vertical: 'top', // Align the top of the menu with the bottom of the anchor
        horizontal: 'right', // Align the right side of the menu with the right side of the anchor
      }}
      sx={{
        // zIndex: 60,
        '& .MuiPaper-root': {
            borderRadius: '16px',
            boxShadow: '1px 10px 25px 0px #03022912',
            padding: '24px 16px',
            maxWidth:320
        },
    }}
    >
      <div className="flex flex-col">
        <div className="flex items-center mb-3 cursor-pointer" onClick={() => handleOptionSelect('Admin')}>
          <div className='min-w-[30px]'>
            <input
              type="radio"
              name="roleOption"
              value="Admin"
              checked={selectedOption === 'Admin'}
              onChange={() => handleOptionSelect('Admin')}
              className="form-radio h-5 w-5 text-custom-blue transition duration-150 ease-in-out mr-3"
            />
          </div>
          <div>
            <span className="font-semibold text-[12px] text-black-blue">Admin</span>
            <p className="text-gray-text text-[10px] font-normal">
              Full control over settings, billing, and team management.
            </p>
          </div>
        </div>

        <div className="flex items-center mb-3 cursor-pointer" onClick={() => handleOptionSelect('Manager')}>
          <div className='min-w-[30px]'>
            <input
              type="radio"
              name="roleOption"
              value="Manager"
              checked={selectedOption === 'Manager'}
              onChange={() => handleOptionSelect('Manager')}
              className="form-radio h-5 w-5 text-custom-blue transition duration-150 ease-in-out mr-3"
            />
          </div>
          <div>
            <span className="font-semibold text-[12px] text-black-blue">Manager</span>
            <p className="text-gray-text text-[10px] font-normal">
              Manage content and general settings, but no access to billing or team management.
            </p>
          </div>
        </div>

        <div className="flex items-center cursor-pointer" onClick={() => handleOptionSelect('Viewer')}>
          <div className='min-w-[30px]'>
            <input
              type="radio"
              name="roleOption"
              value="Viewer"
              checked={selectedOption === 'Viewer'}
              onChange={() => handleOptionSelect('Viewer')}
              className="form-radio h-5 w-5 text-custom-blue transition duration-150 ease-in-out mr-3"
            />
          </div>
          <div>
            <span className="font-semibold text-[12px] text-black-blue">Viewer</span>
            <p className="text-gray-text font-normal text-[10px]">
              Read-only access to all sections. No editing, team management, or billing permissions.
            </p>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default RoleDropdown;
