import React, { useState, useEffect } from 'react';
import { CheckEmail, Closeicon, emailImage } from '../../assets/index';
import AuthLayout from './reusableLayout';
import { Link, useParams } from 'react-router-dom';

const EmailSentPopup = ({ }) => {
    const params = useParams();
    const { email } = params

    const resendClick = async (e) => {
        e.preventDefault();
    };

    return (
        <AuthLayout>
            <div className=" relative">

                <h2 className="text-xl  font-bold text-center mb-5 text-black-blue">
                    Check your mail
                </h2>
                <p className="text-gray-text text-sm font-normal  mb-5 max-w-md mx-auto text-center">
                    Click the link sent to {email} before reset the password.
                </p>
                <div className="flex justify-center mt-4">
                    <img src={CheckEmail} alt="Email" className="w-[200px] h-[180px] object-contain" />
                </div>
                {/* <p className=" mb-7 text-center text-[16px] font-medium text-black-blue">
                    Still can't find the email? No problem.
                </p> */}
                {/* <button
                    className="group relative w-full flex justify-center border border-transparent  text-custom-blue focus:outline-none transition  text-xs font-semibold"
                    onClick={resendClick}
                >
                    Forget Password
                </button> */}
                <div className=" mt-8 text-xs justify-center text-center">
                    <span className="font-medium mr-1.5 text-black-blue">
                        Eamil not sent?{" "}
                    </span>
                    <Link
                        to="/forget-pswd"
                        className=" text-custom-blue hover:underline font-semibold focus:outline-none"
                    >
                        Forgot Password
                    </Link>
                    <span className="font-medium mx-1.5 text-black-blue">
                        or
                    </span>
                    <Link
                        to="/login"
                        className="text-custom-blue font-semibold hover:underline focus:outline-none"
                    >
                        Sign In
                    </Link>
                   
                </div>
            </div>
        </AuthLayout>
    );
};

export default EmailSentPopup;