import React from "react";

const TextInput = ({ type, placeholder, value, name, onChange, error }) => {
  return (
    <div className="relative mb-8">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        className={`w-full bg-custom-gray2 rounded-full  outline-none text-blue-black placeholder-gray-1 text-gray-1 font-medium  transition-all duration-200 px-4 py-5  text-xs ${
          error ? "border-custom-red bg-black " : "border-gray-200"
        }`}
      />
      {error && <p className="text-custom-red text-xs mt-1">{error}</p>}
    </div>
  );
};

export default TextInput;
