import React, { useEffect, useState } from "react";
import { NewLogo, Shape, GifBg, backbutton } from "../../assets/index";
import Carousel from "./DemoCarousal";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../Buttons/BackButton";
export const AuthLayout = ({ children }) => {
    const { pathname } = useLocation();
    console.log(pathname, "pathname")
    const navigate = useNavigate();
    const isAuth = localStorage.getItem("authToken")
    if (isAuth)
        return <Navigate to="/dashboard/campaigns" replace />
    return (
        <div className="container md:h-screen min-w-[100vw] overflow-y-hidden flex flex-col-reverse md:flex-row">
            {/* Left Section */}
            <div className="hidden md:flex items-center bg-custom-blue justify-center  md:w-[60%] relative overflow-hidden">
                <img
                    src={GifBg}
                    alt="Background"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute bottom-0 left-0 z-10">
                    <img src={Shape} alt="Shape" className="w-auto h-auto" />
                </div>
                <div className="w-full md:w-[532px]">
                    <Carousel />
                </div>
            </div>

            {/* Right Section */}
            <div className="md:w-[40%] flex flex-col items-start py-20  gap-8 h-fit md:h-screen justify-center">
                <div className="w-full rounded-3xl px-5 lg:px-24  ">
                    {['/forget-pswd', '/activate-account', '/change-password', '/email-sent', '/resetPassword']
                        .some((route) => pathname.includes(route)) && (
                            <button onClick={() => navigate(-1)} className="">
                                <img src={backbutton} className="w-11 h-11 " alt="Back Button" />
                            </button>
                        )}
                    <div className="flex justify-center  mb-5">
                        <img
                            src={NewLogo}
                            alt="logo"
                            className="h-[120px] w-[120px] object-contain"
                            draggable="false"
                            onContextMenu={(e) => e.preventDefault()}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center mt-5">

                    </div>
                    <div className="">
                        {children}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AuthLayout;