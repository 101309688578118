import { configureStore } from "@reduxjs/toolkit";
import campaignReducer from "./store/campaignSlice";
import domainReducer from "./store/domainSlice/domainSlice";
import userReducer from "./store/userSlice";


const store = configureStore({
  reducer: {
    campaigns: campaignReducer,
    domain: domainReducer,
    user:userReducer
  },
});

export default store;
