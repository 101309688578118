import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { toast } from 'react-toastify';
export const sendInvite = createAsyncThunk(
  'invite/sendInvite',
  async ({ email, role }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/send-invite', { email, role });
      console.log('API Response in Thunk:', response.data); // Check if this logs the response
      return response.data;
    } catch (error) {
      console.error('API Error in Thunk:', error.response?.data || error.message); // Log the error
      return rejectWithValue(error.response.data);
    }
  }
);
export const getTeam = createAsyncThunk(
  'user/getTeam',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/team');
      console.log('API Response in Thunk:', response.data); // Check if this logs the response
      return response.data;
    } catch (error) {
      console.error('API Error in Thunk:', error.response?.data || error.message); // Log the error
      return rejectWithValue(error.response?.data || { message: 'Failed to fetch team members' });
    }
  }
);
export const changeRoles = createAsyncThunk(
  'team/changeRoles',
  async (teamMembers, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/team', { teamMembers });
      console.log('API Response in Thunk:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error in Thunk:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const deleteRole = createAsyncThunk(
  'team/changeRoles',
  async (teamMember, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/team', { teamMember });
      console.log('API Response in Thunk:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error in Thunk:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);