import React from 'react';
import { Link } from 'react-router-dom';

const SwitchButton = ({ currentPage }) => {

  return (
    <div className="flex items-center mx-auto bg-custom-gray2 rounded-full w-56 mt-5">
      <Link
        className={`w-1/2 py-3.5 text-xs font-medium text-center rounded-full transition-all duration-300 ${currentPage === 'login' ? 'bg-custom-blue text-white' : 'text-black-blue'
          }`}
        to='/login'
      >
        Sign In
      </Link>
      <Link
        className={`w-1/2 py-3.5 text-xs font-medium rounded-full text-center transition-all duration-300 ${currentPage === 'signup' ? 'bg-custom-blue text-white' : 'text-black-blue'
          }`}
        to='/signup'
      >
        Sign Up
      </Link>
    </div>
  );
};

export default SwitchButton;