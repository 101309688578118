import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { toast } from 'react-toastify';
// Fetch all labels
export const fetchLabels = createAsyncThunk(
    'labels/fetchLabels',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get('/label');
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  // Create a new label
  export const createLabel = createAsyncThunk(
    'labels/createLabel',
    async (labelData, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post('/label', labelData);
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  // Update an existing label
  export const updateLabel = createAsyncThunk(
    'labels/updateLabel',
    async ({ id, updates }, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.put(`/label/${id}`, updates);
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  // Delete a label
  export const deleteLabel = createAsyncThunk(
    'labels/deleteLabel',
    async (id, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.delete(`/label/${id}`);
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response.data);
      }
    }
  );
  