import React from 'react';
import { AlertIcon2 } from '../../assets';


const Note = ({ noteText }) => {
  return (
    <div className="flex items-center gap-2 p-3 mb-4 mt-7 yellows-icon bg-[#FDFADE] rounded-2xl">
      <AlertIcon2 className="w-4 h-4 mb-7" />
      <div className="text-sm">
        <strong className="text-sm mr-2 font-semibold text-[#C9A110]">
          Note:
        </strong>
        <div className="mt-2 text-black-blue font-normal text-xs">
          {noteText}
        </div>
      </div>
    </div>
  );
};

export default Note;