import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import CustomCheckbox from "../../Components/Buttons/Custom-checkbox";
import TextInput from "../../Components/inputs/textInput";
import PasswordInput from "../../Components/inputs/passwordInput";
import { toast } from "react-toastify";

export const SignUp = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    setErrors({
      ...errors,
      terms: newCheckedState
        ? ""
        : "You must agree to the terms and conditions",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (!isChecked)
      newErrors.terms = "You must agree to the terms and conditions";
    return newErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setloading(true);
    try {
      const response = await axiosInstance.post("/signup", formData);
      const { success } = response.data;
      if (success) {
        toast.success(response.data.message || "Account created, Check your email account for verification!");
        setErrors({})
        setTimeout(() => {

          navigate(`/activate-account/${formData.email}`)
        }, 2000);
      } else {
        const { error, message } = response.data;
        setErrors({ ...errors, [error]: message });
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setloading(false);
  };

  return (
    <div className="w-full" >
      <form
        className="w-full"
        onSubmit={handleFormSubmit}
      >
        <div className="rounded-md flex  w-full flex-col ">
          <div className="flex flex-col">
            <label htmlFor="firstName" className="text-sm text-black-blue mb-3 font-medium">Name</label>
            <div className="flex flex-col sm:flex-row   gap-x-8">
              <div className=" sm:w-1/2">
                <TextInput
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleInputChange}
                  error={errors.firstName}
                />
              </div>
              <div className=" sm:w-1/2">
                <TextInput
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleInputChange}
                  error={errors.lastName}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="email" className="text-sm text-black-blue mb-3 font-medium">Email</label>
            <TextInput
              id="email"
              type="email"
              placeholder="Email"
              value={formData.email}
              name="email"
              onChange={handleInputChange}
              error={errors.email}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="password" className="text-sm text-black-blue mb-3 font-medium">Password</label>
            <PasswordInput
              id="password"
              placeholder="Password"
              value={formData.password}
              name="password"
              onChange={handleInputChange}
              error={errors.password}
            />
          </div>
        </div>
        <div>
          <CustomCheckbox
            label="By proceeding, you agree to the "
            label2="Terms and Conditions"
            onChange={handleCheckboxChange}
            checked={isChecked}
          />
          <div className="relative mt-[5px]">
            {errors.terms && (
              <p className="text-custom-red ps-2 mt-1 text-xs">{errors.terms}</p>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center w-[50%] mx-auto mt-8">
          <button
            disabled={loading}
            type="submit"
            className="items-center w-full p-4 border border-transparent rounded-full text-white text-sm bg-custom-blue  focus:outline-none transition  font-medium"
          >
            {loading ? "Continue" : "Continue"}
          </button>
        </div>
      </form>
    </div >
  );
};

export default SignUp;
