import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../assets/Illusrtations/404.json';
import { LeftArrow } from '../../assets';

const NotFoundPage = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <div className="flex flex-col-reverse lg:flex-row items-center justify-evenly min-h-screen p-8 lg:p-[80px] ">
            {/* Left Side Content */}
            <div className="flex flex-col items-start w-full lg:w-1/2 gap-8">
                <h2 className="mt-4 text-3xl md:text-4xl lg:text-5xl font-semibold">
                    Oops! Page not found
                </h2>
                <p className="mt-2 text-base md:text-lg">
                    The page you are trying to access is unavailable. It may have been relocated, removed, or the link could be outdated.
                </p>
                <div className="flex">
                    <button
                        onClick={handleClick}
                        className="flex items-center justify-center px-4 py-3 white-icon bg-custom-blue text-white rounded-full"
                    >
                        <LeftArrow className="h-5 w-5 mr-2 rotate-180" />
                        <span>Back To Home</span>
                    </button>
                </div>
            </div>

            {/* Lottie Animation */}
            <div className="w-full lg:w-[35%] flex justify-center items-center">
                <Lottie options={defaultOptions} />
            </div>
        </div>
    );
};

export default NotFoundPage;
