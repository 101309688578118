import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SwitchButton from "../../Pages/SignIn/SwitchButton";
import SignInPage from "../../Pages/SignIn/SignIn";
import SignUp from "../../Pages/SignIn/Signup";
import AuthLayout from "./reusableLayout";

export const Register = () => {
  const location = useLocation();
  const currentPage = location.pathname.slice(1);

  return (
    <AuthLayout className="bg-white font-poppins">
      <div className="mx-auto ">
        <h2 className="text-center text-[16px] tracking-wide font-black text-black-blue">
          Welcome to Primary Inbox
        </h2>
        <SwitchButton currentPage={currentPage} />
      </div>
      <div className="mt-8 ">
        {currentPage === 'login' ? <SignInPage /> : <SignUp />}
      </div>
    </AuthLayout>
  );
};

export default Register;