import React from 'react';
import Loading from "../../assets/Illusrtations/Loading.json"
import Lottie from "react-lottie";

const Done = ({ animation, heading, text, text2 }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loading,
        rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center px-4 bg-black bg-opacity-50">
            <div className="bg-white px-4 py-10 rounded-[32px] shadow-lg max-w-[530px] md:w-[530px] relative">
                <div className='flex justify-center mx-auto w-[120px] h-[120px] p-4 rounded-full card-ss bg-white'>
                    <div className="flex justify-center ">
                        {animation}
                    </div>
                </div>
                <p className="mt-8 text-center text-lg font-semibold text-black-blue">
                    {heading}
                </p>
                <p className="mt-3 text-center text-sm font-normal text-gray-text">
                    {text}
                </p>
                <p className="text-center text-sm font-normal text-gray-text">
                    {text2}
                </p>
                <div className="flex justify-center w-10 h-10  mx-auto mt-8 ">
                    <Lottie options={defaultOptions} />
                </div>
            </div>
        </div>
    );
};

export default Done; 