import React from "react";
import Loading from "../../assets/Illusrtations/Loading.json"
import Lottie from "react-lottie";
const Loader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loading,
    
        

        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    };
    return (
        <div className="flex justify-center w-full items-center h-[80vh]"> 
        <div className="">
        <Lottie options={defaultOptions} />
        </div>
        </div>
    );
};

export default Loader;
export const LoaderSm = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loading,
    
        

        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice',
        // },
    };
    return (
        <div className="flex justify-center w-full items-center "> 
        <div className="">
        <Lottie options={defaultOptions} />
        </div>
        </div>
    );
};
 