import React, { useState } from "react";
import {HidePasswordIcon,ShowPasswordIcon} from "../../assets/index"; 

const PasswordInput = ({ placeholder, value, name, onChange, error, bg="bg-custom-gray2" }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        className={`w-full ${bg} rounded-full focus:outline-none outline-none text-balck-blue placeholder-gray-1 text-black-blue transition-all duration-200  px-4  py-5 text-xs font-medium`}
      />
      <button
        type="button"
        className={`absolute right-6 top-1/2 transform -translate-y-1/2 text-black/70 hover:text-black transition-colors duration-200 blue-icon `}
        onClick={togglePasswordVisibility}
      >
        <img
          src={showPassword ?  ShowPasswordIcon : HidePasswordIcon }
          alt={showPassword ? "Hide password" : "Show password"}
          className="w-5 h-5"
        />
      </button>
      {error && (
        <p className="text-custom-red ps-2  text-xs mt-0.5">{error}</p>
      )}
    </div>
  );
};

export default PasswordInput;
