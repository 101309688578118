import React from "react";
import { Line, TickIcon } from "../../assets";

const DoubleArrow = ({ currentStep, stepIndex, className }) => (
  <div className={`flex ${className}`}>
    <div
      className={`h-[1px] w-10 transition-colors duration-300 ease-in-out ${
        currentStep >= stepIndex
          ? 'bg-[#684FFF]' // Solid purple line when the step is reached
          : 'border-dashed border-[1px] border-[#E5E7EB]' // Dotted line for steps not yet reached
      }`}
    ></div>
  </div>
);

const Stepper = ({ steps, currentStep,onStepClick={} }) => {
  return (
    <div className="flex items-center space-x-0 xl:space-x-4">
      {steps &&
        steps.map((step, index) => (
          <React.Fragment key={step.number}>
            <div
              className={`flex items-center cursor-pointer rounded-full ${
                index === currentStep
                  ? "bg-[#E1DBFF] text-[#684FFF]" // Active step (light purple background)
                  : index < currentStep
                  ? "bg-[#684FFF] text-white" // Completed steps
                  : "bg-[#F3F4F6] text-[#6B7280]" // Future steps
              } px-2 pr-4 py-2 text-[14px]`}
              aria-current={index === currentStep ? "step" : undefined} 
              onClick={()=>onStepClick(index)}
            >
              {index < currentStep ? (
                <div
                  className={`bg-white text-white purple-icon h-8 w-8 rounded-full mr-2 flex items-center justify-center font-medium`}
                >
                  <TickIcon className="w-5 h-5" /> {/* Checkmark for completed steps */}
                </div>
              ) : (
                <div className="flex mr-2 text-white rounded-full items-center">
                  <span
                    className={`text-[14px] ${
                      index === currentStep
                        ? "bg-[#684FFF] text-white" // Active step number
                        : "bg-white text-[#6B7280]" // Future step number
                    } p-2 w-8 h-8 rounded-full items-center justify-center lg:text-[14px] flex`}
                  >
                    {step.number}
                  </span>
                </div>
              )}

              {/* Text for all screen sizes */}
              <span className={`text-[10px] lg:text-[14px] text-nowrap inline`}>
                {step.name}
              </span>
            </div>

            {index < steps.length - 1 && (
              <DoubleArrow currentStep={currentStep} stepIndex={index + 1} />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default Stepper;
