import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { toast } from 'react-toastify';
export const fetchPrimaryUser = createAsyncThunk(
    'user/fetchPrimaryUser',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/p-u`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);
export const deleteSessions = createAsyncThunk(
    'session/deleteSessions',
    async ({ sessionIds, logout }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/exp-s', { sessionIds, logout });
            console.log(`${response.data.message}`);
            return sessionIds; // Return the deleted session IDs for potential state cleanup
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const editPrimaryUser = createAsyncThunk(
    'user/editUser',
    async ({ userId, updates }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put(`/p-u/${userId}`, updates);
            console.log('User Updated:', response.data);
            return response.data; // Return updated user data
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to update user');
        }
    }
);
export const updatePassword = createAsyncThunk(
    'user/updatePassword',
    async ({ currentPassword, newPassword }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put('/upd-p', {
                currentPassword,
                newPassword,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to update password');
        }
    }
);
export const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) {
        try {
            return JSON.parse(decodeURIComponent(match[2]));
        } catch (error) {
            console.error("Error parsing cookie:", error);
            return null;
        }
    }
    return null;
};
// Thunk to upload the avatar image
export const uploadAvatar = createAsyncThunk(
    'user/uploadAvatar',
    async (file, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('image', file); // 'image' should match the field name expected in your backend

            const response = await axiosInstance.post('/upload-image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data; // Response data should contain image URL or other info
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || "Failed to upload avatar";
            toast.error(errorMessage);
            return rejectWithValue(errorMessage);
        }
    }
);