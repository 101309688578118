import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../../../Components/Buttons/Custom-checkbox';
import Note from '../../../Components/Note/note';
import Stepper from '../../../Components/stepper/stepperHeader';
import ProgressFooter from '../../../Components/stepper/progressFooter';
import BackButton from '../../../Components/Buttons/BackButton';
import ReusableTooltip from './tooltip';
import RoleDropdown from '../../Settings/RolrdropDown';

const TestConfiguration = () => {
  const navigate = useNavigate();
  const [deliveryMethod, setDeliveryMethod] = useState('scheduled');

  const steps = [
    { number: "01", name: "Test Configuration" },
    { number: "02", name: "Content & Mailboxes Setup" }
  ];

  const handleSubmit = () => {
    navigate('/next-page');
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  const handleDeliveryMethodChange = (method) => {
    setDeliveryMethod(method);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="relative">
        <div className="absolute left-8 top-1/2 -translate-y-1/2">
          <BackButton />
        </div>
        
        <div className="flex justify-center py-4">
          <Stepper 
            steps={steps} 
            currentStep={0}
            onStepClick={() => {}}
          />
        </div>
      </div>

      <div className="flex-grow px-8 py-6">
        <div className="flex flex-col justify-center items-center mx-auto max-w-[657px] gap-6">
          <div className="flex flex-col gap-4 items-start justify-start text-start">
            <p className="text-[18px] font-semibold text-black-blue">Set Basic Test Information</p>
            <p className="text-gray-1 font-normal text-start text-sm">
              Provide a name and optional description to identify and describe your test clearly.
            </p>
          </div>

          <div className="w-full">
            <div className="flex flex-col gap-2 mb-6">
              <label htmlFor="testName" className="font-medium text-[14px] text-black-blue">Name</label>
              <input
                id="testName"
                type="text"
                placeholder="Name your test (e.g., spam filter test)"
                className="w-full p-3 border border-custom-gray rounded-full focus:outline-none text-gray-1 text-xs"
              />
            </div>

            <div className="flex flex-col gap-2 mb-8">
              <label htmlFor="description" className="font-medium text-black-blue">Description</label>
              <textarea
                id="description"
                placeholder="Provide details or notes for this test (optional)"
                className="w-full p-3 border border-custom-gray rounded-2xl h-24 resize-none focus:outline-none text-gray-1 text-xs"
              />
            </div>

            <div className="flex flex-col gap-8 mb-8">
              <div>
                <p className="font-semibold text-lg text-black-blue mb-4">Apply Spam Checks</p>
                <p className="text-sm text-gray-1 font-normal">
                  Test your email's content with advanced spam filters to improve inbox placement
                  and reduce the chance of being marked as spam.
                </p>
              </div>
              <label className="flex items-center gap-2 cursor-pointer">
                <CustomCheckbox />
                <p className="text-sm font-normal text-black-blue">Email Spam Detector</p>
              </label>
            </div>

            <div className="flex flex-col mb-4">
              <div>
                <p className="font-semibold text-lg text-black-blue mb-4">Validate Links Automatically</p>
                <p className="text-sm font-normal text-gray-1 mb-8">
                  Ensure all email links are valid, excluding unsubscribe links, to maintain link
                  integrity and enhance deliverability.
                </p>
              </div>
              <label className="flex items-center gap-2 cursor-pointer">
                <CustomCheckbox />
                <span className="text-sm font-normal text-black-blue">Scan Links Within Email</span>
              </label>
              <Note noteText="Using Scan links may affect click rates. Turn off link checking if needed." />
            </div>

            <div className="flex flex-col gap-4 mb-8">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <p className="font-semibold text-lg text-black-blue">Control Email Sending Speed</p>
                  <ReusableTooltip
                    content={{
                      description: "Optimize your ESP performance by sending emails, to each provider individually or all at once, based on your delivery needs."
                    }}
                    iconType="info"
                    className="bg-custom-blue2"
                    placement="top-start"
                  />
                </div>
              </div>
              <p className="text-sm font-normal text-gray-1">
                Adjust the speed of your email delivery for optimal performance. Send emails in
                intervals or all at once.
              </p>
              <div className="flex gap-5">
                <label className="flex items-center gap-2 cursor-pointer">
                  <input 
                    type="radio" 
                    name="sendingSpeed" 
                    checked={deliveryMethod === 'scheduled'}
                    onChange={() => handleDeliveryMethodChange('scheduled')}
                    className="w-4 h-4" 
                  />
                  <span className="text-sm text-black-blue">Scheduled Email Delivery</span>
                  {deliveryMethod === 'scheduled' && <RoleDropdown />}
                  <ReusableTooltip
                    content={{
                      description: "If you choose one mailbox and 30 receivers, emails will be sent with 3-5 second intervals. This method works best with bulk mailing SMTPs. Avoid using it with standard ESPs like Gmail or Outlook to prevent issues."
                    }}
                    iconType="mark"
                    className="bg-custom-blue"
                    placement="top-start"
                  />
                </label>
                <label className="flex items-center gap-2 cursor-pointer">
                  <input 
                    type="radio" 
                    name="sendingSpeed" 
                    checked={deliveryMethod === 'instant'}
                    onChange={() => handleDeliveryMethodChange('instant')}
                    className="w-4 h-4" 
                  />
                  <span className="text-sm text-black-blue">Instant Bulk Delivery</span>
                  <ReusableTooltip
                    content={{
                      description: "Sends all emails immediately in bulk."
                    }}
                    iconType="mark"
                    className="bg-custom-blue"
                    placement="top-start"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProgressFooter
        title="Manual Deliverability Test"
        currentStep={0}
        steps={steps}
        handlePrevious={handlePrevious}
        handleSubmit={handleSubmit}
        loading={false}
      />
    </div>
  );
};

export default TestConfiguration;