import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { InfoIcon, Mark } from "../../../assets";

// Custom styling for the tooltip content
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-arrow`]: {
    position: 'absolute',
    bottom: '-6px',
    left: '80%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '15px solid rgb(98, 69, 255)',
  },
  [`& .MuiTooltip-tooltip`]: {
    background: 'rgb(98, 69, 255)',
    color: "#FFFFFF",
    maxWidth: 471,
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "16px",
    boxShadow: `-3px 2px 18px rgba(0, 0, 0, 0.1)`,
    backgroundSize: 'cover',
    position: 'relative',
    marginBottom: '12px',
  },
  [`& .MuiTypography-body2`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    lineHeight: 1.5,
  },
  ul: {
    // marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    "& li": {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
  },
}));

// Reusable tooltip component
const ReusableTooltip = ({ 
  content = {
    title: "",
    description: "Optimize your ESP performance by sending emails to each provider individually or all at once, based on your delivery needs.",
    bulletPoints: [],
  },
  placement = "top-start",
  iconType = "info",
  className
}) => {
  // Determine which background color class to use based on icon type
  const bgColorClass = iconType === "mark" ? "bg-custom-blue" : "bg-custom-blue2";
  
  // Use the provided className or fall back to the determined bgColorClass
  const finalClassName = className || bgColorClass;

  // Determine icon color class based on background
  const getIconColorClass = (bgClass) => {
    return bgClass.includes("custom-blue2") ? "text-custom-blue" : "text-white";
  };

  const iconColorClass = getIconColorClass(finalClassName);

  // Render the appropriate icon based on iconType
  const renderIcon = () => {
    const iconSize = iconType === "mark" ? "h-3 w-3" : "w-3 h-3";
    if (iconType === "mark") {
      return <Mark className={`${iconSize} ${iconColorClass} stroke-2`} />;
    }
    return <InfoIcon className={`${iconSize} ${iconColorClass}`} />;
  };

  // Render tooltip content based on provided content structure
  const renderContent = () => (
    <React.Fragment>
      <Typography variant="body2" component="div">
        {/* Title */}
        {content.title && (
          <p className="text-xs leading-5 font-medium mb-5 text-white">
            {content.title}
          </p>
        )}
        
        {/* Description (simple text) */}
        {content.description && (
          <p className="text-xs font-medium text-white mt-1">
            {content.description}
          </p>
        )}
        
        {/* Bullet Points */}
        {content.bulletPoints && content.bulletPoints.length > 0 && (
          <ul>
            {content.bulletPoints.map((point, index) => (
              <li key={index} className="text-xs font-medium text-white">
                <div className="w-1 h-1 bg-white rounded-full mr-2"></div>
                {point}
              </li>
            ))}
          </ul>
        )}
      </Typography>
    </React.Fragment>
  );

  return (
    <div className="card-ss">
      <CustomTooltip
        title={renderContent()}
        placement={placement}
      >
        <div className={`p-1 mb-2 rounded-full ${finalClassName}`}>
          {renderIcon()}
        </div>
      </CustomTooltip>
    </div>
  );
};

export default ReusableTooltip;