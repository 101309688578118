import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import { Checkbox, CheckIcon, Check1 } from '../../assets';

const CustomCheckbox = ({ checked, onChange, label, defaultChecked, number, className, disabled = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || false);

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label className={`flex items-center cursor-pointer ${className}`}>
      <div className="relative">
        <input
          type="checkbox"
          disabled={disabled}
          className="sr-only"
          checked={isChecked}
          onChange={handleChange}
        />
        <div
          className={`w-5 h-5 rounded-full transition-colors duration-200 ease-in-out flex items-center justify-center ${disabled && ' cursor-not-allowed'} ${isChecked
              ? 'bg-[#684FFF]'
              : 'bg-gray-bg'
            }`}
        >
          {typeof(number) === "number" ? (
            <span className={`text-xs font-medium ${isChecked ? 'text-white' : 'text-gray-500'}`}>
              {number}
            </span>
          ) : (
            <Check className='h-[14px] w-[14px]' color={isChecked ? 'white' : 'currentColor'} />
          )}
        </div>
      </div>
      {label && <span className="ml-2">{label}</span>}
    </label>
  );
};

export default CustomCheckbox;
