import { BASE_URL } from "./config";
import axios from 'axios';

const token = localStorage.getItem("authToken");
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${token}`,
  },
  withCredentials: true,
  timeout: 800000,
});

// Global request and response interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    // You can perform actions before the request is sent
    return config;
  },
  (error) => {
    // Handle request errors
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // console.error('Error Response:', error.response.data);
      // console.error('Error Status:', error.response.status);
      // console.error('Error Headers:', error.response.headers);

      // Redirect based on error status
      if (error.response.status === 401) {
        console.error('Unauthorized access - redirecting to login.');
        // Redirect to login or error page
        window.location.href = '/login'; 
      }
      // else if (error.response.status === 404) {
      //   console.error('Resource not found.');
      //   window.location.href = '/error'; // Redirect to error page for 404
      // }
      // else if (error.response.status === 500) {
      //   console.error('Resource not found.');
      //   window.location.href = '/error'; // Redirect to error page for 404
      // }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
