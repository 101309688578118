import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPrimaryUser, getCookie } from './setting';
import { fetchBlockedList } from './blockList';
import { fetchLabels } from './labels';
import { fetchTags } from './tags';
// import axiosInstance from '../../utils/axiosInstance';
// import { toast } from 'react-toastify';
export { editPrimaryUser, deleteSessions, getCookie, fetchPrimaryUser, updatePassword } from './setting';
export { delFromBlocklist, fetchBlockedList, uploadManualBlocklist, uploadSheet } from './blockList';
export { createLabel, deleteLabel, updateLabel, fetchLabels } from './labels';
export { changeRoles, deleteRole, getTeam, sendInvite } from './team';
export { createTag, deleteTag, updateTag, fetchTags } from './tags'

// Async thunk for API call
// export const fetchPrimaryUser = createAsyncThunk(
//   'user/fetchPrimaryUser',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.get(`/p-u`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data || 'An error occurred');
//     }
//   }
// );
// export const deleteSessions = createAsyncThunk(
//   'session/deleteSessions',
//   async ({ sessionIds, logout }, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/exp-s', { sessionIds, logout });
//       console.log(`${response.data.message}`);
//       return sessionIds; // Return the deleted session IDs for potential state cleanup
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );
// export const editPrimaryUser = createAsyncThunk(
//   'user/editUser',
//   async ({ userId, updates }, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.put(`/p-u/${userId}`, updates);
//       console.log('User Updated:', response.data);
//       return response.data; // Return updated user data
//     } catch (error) {
//       return rejectWithValue(error.response?.data || 'Failed to update user');
//     }
//   }
// );
// export const updatePassword = createAsyncThunk(
//   'user/updatePassword',
//   async ({ currentPassword, newPassword }, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.put('/upd-p', {
//         currentPassword,
//         newPassword,
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data || 'Failed to update password');
//     }
//   }
// );
// export const getCookie = (name) => {
//   const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
//   if (match) {
//     try {
//       return JSON.parse(decodeURIComponent(match[2]));
//     } catch (error) {
//       console.error("Error parsing cookie:", error);
//       return null;
//     }
//   }
//   return null;
// };
// export const sendInvite = createAsyncThunk(
//   'invite/sendInvite',
//   async ({ email, role }, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/send-invite', { email, role });
//       console.log('API Response in Thunk:', response.data); // Check if this logs the response
//       return response.data;
//     } catch (error) {
//       console.error('API Error in Thunk:', error.response?.data || error.message); // Log the error
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
// export const getTeam = createAsyncThunk(
//   'user/getTeam',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.get('/team');
//       console.log('API Response in Thunk:', response.data); // Check if this logs the response
//       return response.data;
//     } catch (error) {
//       console.error('API Error in Thunk:', error.response?.data || error.message); // Log the error
//       return rejectWithValue(error.response?.data || { message: 'Failed to fetch team members' });
//     }
//   }
// );
// export const changeRoles = createAsyncThunk(
//   'team/changeRoles',
//   async (teamMembers, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.put('/team', { teamMembers });
//       console.log('API Response in Thunk:', response.data);
//       return response.data;
//     } catch (error) {
//       console.error('API Error in Thunk:', error.response?.data || error.message);
//       return rejectWithValue(error.response?.data || error.message);
//     }
//   }
// );
// export const deleteRole = createAsyncThunk(
//   'team/changeRoles',
//   async (teamMember, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/team', { teamMember });
//       console.log('API Response in Thunk:', response.data);
//       return response.data;
//     } catch (error) {
//       console.error('API Error in Thunk:', error.response?.data || error.message);
//       return rejectWithValue(error.response?.data || error.message);
//     }
//   }
// );
// export const fetchBlockedList = createAsyncThunk(
//   'blocklist/fetchBlockedList',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.get('/block-list');
//       console.log('API Response in Thunk:', response.data);
//       return response.data;
//     } catch (error) {
//       console.error('API Error in Thunk:', error.response?.data || error.message);
//       return rejectWithValue(error.response?.data || error.message);
//     }
//   }
// );
// // 
// export const uploadSheet = createAsyncThunk(
//   'blocklist/fetchBlockedList',
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/sheet-block', data);
//       console.log('API Response in Thunk:', response.data);
//       return response.data;
//     } catch (error) {
//       const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
//       toast.error(errorMessage);
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
// export const uploadManualBlocklist = createAsyncThunk(
//   'blocklist/uploadManualBlocklist',
//   async (emails, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/manual-block', { emails });
//       return response.data;
//     } catch (error) {
//       const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
//       toast.error(errorMessage);
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
// export const delFromBlocklist = createAsyncThunk(
//   'blocklist/deleteLeads',
//   async (emails, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('del-block', { emails });
//       return response.data;
//     } catch (error) {
//       const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
//       toast.error(errorMessage);
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const initialState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    timeZone: '',
    team: [],
    blockedEntries: [],
    tags: [],
    labels: []
  },
  status: 'idle',
  sid: getCookie('pu_sid') || null,
  error: null,
};

// Create the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      // Cases for fetching primary user
      .addCase(fetchPrimaryUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPrimaryUser.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        state.status = 'succeeded';
      })
      .addCase(fetchPrimaryUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Cases for fetching blocked list
      .addCase(fetchBlockedList.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchBlockedList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.blockedEntries = action.payload.blockedEntries;
      })
      .addCase(fetchBlockedList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Cases for fetching labels
      .addCase(fetchLabels.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.labels = action.payload.labels;
      })
      .addCase(fetchLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Cases for fetching tags
      .addCase(fetchTags.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        console.log(action.payload, " action.payload")
        state.status = 'succeeded';
        state.user.tags = action.payload.tags; 
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});


export default userSlice.reducer;
