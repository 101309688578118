import React from "react";

const ProgressFooter = ({
  title='Campaign Creation',
  currentStep,
  steps,
  isActive = false, 
  handlePrevious,
  handleSubmit, 
  loading, 
}) => {
  return (
    <footer className="bg-white py-5 px-12 fixed  bottom-0 w-screen left-0 right-0  flex  gap-5  flex-row justify-between items-center camp-footer">
      <div className="w-full md:w-[600px]"> 
        <p className="text-gray-500 text-[10px] mb-2">
          Steps {currentStep + 1}/{steps.length}
        </p>
        <h2 className="text-[12px] font-bold text-gray-900 mb-2">
          {title}
        </h2>
        <div className="bg-gray-200 h-2 rounded-full relative overflow-hidden w-[270px]">
          <div
            className="bg-purple-500 h-full rounded-full transition-all duration-300 ease-in-out"
            style={{
              width: `${((currentStep + 1) / steps.length) * 100}%`,
            }}
            role="progressbar"
            aria-valuenow={((currentStep + 1) / steps.length) * 100}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <div className=" w-fit flex items-center justify-between">
        <button
          className={`${currentStep === 0 ? "opacity-0 cursor-not-allowed" : ""
            } px-12 py-4 border-2 lg:min-w-[160px] border-custom-blue text-custom-blue rounded-full mr-4 text-base font-[16px] transition-opacity`}
          onClick={handlePrevious}
          disabled={currentStep === 0}
        >
          Previous
        </button>

        <button
          className="bg-custom-blue lg:min-w-[160px] px-12 border-2 border-custom-blue text-white   py-4 rounded-full text-base font-[16px]"
          onClick={handleSubmit} // Use the passed handleSubmit function
          disabled={loading}
        >
          {(currentStep === steps.length - 1 && isActive) ? "Update" : currentStep === steps.length - 1 ? "Launch" : "Next"}
        </button>
      </div>
    </footer>
  );
};

export default ProgressFooter;
