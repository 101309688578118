import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import TextInput from "../../Components/inputs/textInput";
import PasswordInput from "../../Components/inputs/passwordInput";
import CustomCheckbox from "../../Components/Buttons/Custom-checkbox";
import { toast } from "react-toastify";

const SignInPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axiosInstance.post("/login", {
        ...formData,
        rememberMe,
      });
      const { success, message, user } = response.data;
      if (success) {
        
      toast.success(`Welcome back, ${user.firstName}`)
        localStorage.setItem("authToken", user.token); 
        navigate("/dashboard");
        window.location.reload();
      } else {
        const { error, message } = response.data;
        setErrors({
          ...errors,
          [error]: message,
        });
      }
    } catch (error) {
      setErrors({ general: error.message });
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    < >
      <div className="">

        <form onSubmit={handleSubmit} className="">
          <div className="">
            <div className="flex flex-col">
              <label htmlFor="email" className="text-sm text-black-blue mb-3 font-medium">Email</label>
              <TextInput
                type="email"
                placeholder="Email"
                value={formData.email}
                name="email"
                onChange={handleInputChange}
                error={errors.email}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className="text-sm text-black-blue mb-3 font-medium">Password</label>
              <PasswordInput
                placeholder="Password"
                value={formData.password}
                name="password"
                onChange={handleInputChange}
                error={errors.password}
              />
            </div>
          </div>

          <div className="flex items-center text-xs text-black-blue font-semibold justify-between mt-4 ">
            <CustomCheckbox
              label="Remember me"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <Link
              to="/forget-pswd"
              className="text-xs text-custom-blue hover:underline font-semibold focus:outline-none"
            // onClick={handleForgotPasswordClick}
            >
              Forgot Password?
            </Link>
          </div>
          <div className="flex justify-center items-center w-[50%] mx-auto mt-8">
            <button
              type="submit"
              className={`w-full text-sm font-medium bg-custom-blue p-4  text-white rounded-full transition duration-300 ${isSubmitting
                ? "opacity-70 cursor-not-allowed"
                : ""
                }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Logging in..." : "Sign In"}
            </button>
          </div>
        </form>

        {errors.general && (
          <p className="text-custom-red text-sm text-center">{errors.general}</p>
        )}


      </div>
    </ >
  );
};

export default SignInPage;
