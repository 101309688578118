import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import AuthLayout from "../../Components/Forgot-Password/reusableLayout";
import resetAnimation from "../../assets/Illusrtations/Done.json";
import Lottie from 'react-lottie';
import Done from "../../Components/Forgot-Password/confirmation";
import { toast } from "react-toastify";

export const ActivateAccount = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
    const [loading, setloading] = useState(false);
    const [verify, setverify] = useState(false)
    const { email } = useParams();
    const [verificationError, setVerificationError] = useState(null);
    const [verificationText, setVerificationText] = useState(null);
    const navigate = useNavigate();
    const inputRefs = useRef([]);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: resetAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 4);
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const handleVerificationChange = (index, value) => {
        if (isNaN(value)) return; // Prevent non-numeric input

        const newCode = [...verificationCode];
        newCode[index] = value;
        setVerificationCode(newCode); // Update state 

        if (value !== '' && index < 3) {
            inputRefs.current[index + 1].focus(); // Move to next input
        }
    };

    // Use useEffect to check when the entire code is filled
    useEffect(() => {
        if (verificationCode.every(digit => digit !== '')) {
            handleVerifyAccount();
        }
    }, [verificationCode]);
    const handleKeyDown = (index, e) => {
        // Move to previous input on backspace if current input is empty
        if (e.key === 'Backspace' && index > 0 && verificationCode[index] === '') {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleVerifyAccount = async () => { 
        setVerificationError(null)
        if (verificationCode.some((digit) => digit === "" || verificationCode.length !== 4)) {
            setVerificationError("Verification code must be 4 digits");
            return;
        }
        if (!email) return toast.error("Email is missing or invalid")
        if (verify) return;
        setverify(true);
        try {
            const token = verificationCode.join("");
            const response = await axiosInstance.post("/activate-account", {
                email: email,
                token,
            });

            if (response.data.success) {
                toast.success("Account activated!");
                setVerificationCode(["", "", "", ""]);
                setIsPopupOpen(true)
                setTimeout(() => {
                    navigate("/login");
                }, 2000);

            } else {
                setVerificationError(response.data.message);
            }
        } catch (error) {
            setVerificationError("Server error during activation.");
        }
        setverify(false);

    };

    const sendNewCode = async (e) => {
        e.preventDefault();
        if (loading) return;
        setloading(true);
        try {

            setVerificationError(null)
            setVerificationCode(["", "", "", ""]);
            const response = await axiosInstance.post("/resend-Token", {
                email: email,
            });
            const { success } = response.data;
            if (success) {
                toast.success(`A 4-digit code has been sent to ${email}`);
                setVerificationText(
                    `A 4-digit code has been sent to ${email}`
                );
                setVerificationError(null)
            } else {
                toast.error(response.data.message || "Failed to verify email!")
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error(error.message || "Failed to verify email!")
        }
        setloading(false);
    };

    return (
        <AuthLayout className="w-full">
            <>
                <h2 className="text-center text-xl font-bold text-black-blue sm:text-nowrap">
                    Enter verification code
                </h2>
                {verificationText && (
                    <p className="mt-2 text-center text-sm text-gray-text">
                        {verificationText}
                    </p>
                )}
                <p className="text-center text-sm font-normal mt-5 text-black-blue md:text-nowrap">
                    A 4-digit code has been sent to {email || "your email"}
                </p>
                <div className="flex flex-col justify-center max-w-[392px] mx-auto">
                    <div className="mt-[28px] flex justify-between gap-6 ">
                        {verificationCode.map((digit, index) => (
                            <input
                                key={index}
                                ref={el => inputRefs.current[index] = el}
                                type="tel"
                                maxLength="1"
                                className="w-20 h-20 text-center text-3xl text-black-blue font-bold bg-custom-gray2 rounded-3xl focus:outline-custom-blue "
                                value={digit}
                                onChange={(e) => handleVerificationChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                            />
                        ))}
                    </div>
                    {verificationError && (
                        <p className="text-custom-red ps-2 text-xs mt-1 text-center">
                            {verificationError}
                        </p>
                    )}
                    <button
                        onClick={sendNewCode}
                        disabled={loading || verify}
                        className="text-xs font-semibold text-custom-blue text-end mt-4"
                    >
                        {loading ? "Sending..." : "Send Again"}
                    </button>
                </div>

                <button
                    disabled={loading || verify}
                    className="mt-6 w-1/2  px-4 py-4 mx-auto block text-sm rounded-full text-white bg-custom-blue focus:outline-none font-medium"
                    onClick={handleVerifyAccount}
                >
                    {verify ? "Verifying" : "Verify"}
                </button>
            </>
            {isPopupOpen && (
                <Done
                    text="You're all set! Get ready to unlock powerful insights and"
                    text2='streamline your experience with us. Let’s get started!'
                    heading='Welcome to primary inbox'
                    animation={<Lottie options={defaultOptions} />
                    }
                />
            )}
        </AuthLayout>
    );
};

export default ActivateAccount;