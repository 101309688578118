import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { toast } from 'react-toastify';
export const fetchBlockedList = createAsyncThunk(
    'blocklist/fetchBlockedList',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get('/block-list');
            console.log('API Response in Thunk:', response.data);
            return response.data;
        } catch (error) {
            console.error('API Error in Thunk:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);
// 
export const uploadSheet = createAsyncThunk(
    'blocklist/fetchBlockedList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/sheet-block', data);
            console.log('API Response in Thunk:', response.data);
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            toast.error(errorMessage);
            return rejectWithValue(errorMessage);
        }
    }
);
export const uploadManualBlocklist = createAsyncThunk(
    'blocklist/uploadManualBlocklist',
    async (emails, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/manual-block', { emails });
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            toast.error(errorMessage);
            return rejectWithValue(error.response.data);
        }
    }
);
export const delFromBlocklist = createAsyncThunk(
    'blocklist/deleteLeads',
    async (emails, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('del-block', { emails });
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred";
            toast.error(errorMessage);
            return rejectWithValue(error.response.data);
        }
    }
);
