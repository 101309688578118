import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  domainSuggestions: [],
  selectedDomains: [],
  lastSearchedTerm: "",
  selectedProvider: null, // New state property
};

export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setDomainSuggestions: (state, action) => {
      state.domainSuggestions = action.payload;
    },
    setSelectedDomains: (state, action) => {
      state.selectedDomains = action.payload;
    },
    addSelectedDomain: (state, action) => {
      state.selectedDomains.push(action.payload);
    },
    removeSelectedDomain: (state, action) => {
      state.selectedDomains = state.selectedDomains.filter(
        (domain) => domain !== action.payload
      );
    },
    setLastSearchedTerm: (state, action) => {
      state.lastSearchedTerm = action.payload;
    },
    setSelectedProvider: (state, action) => {
      // New reducer
      state.selectedProvider = action.payload;
    },
    clearDomainState: (state) => {
      return initialState;
    },
  },
});

export const {
  setSearchTerm,
  setDomainSuggestions,
  setSelectedDomains,
  addSelectedDomain,
  removeSelectedDomain,
  setLastSearchedTerm,
  setSelectedProvider, // New action
  clearDomainState,
} = domainSlice.actions;

export default domainSlice.reducer;
