import React, { useState, useEffect } from "react"; 
import axiosInstance from "../../utils/axiosInstance";
import TextInput from "../inputs/textInput";
import AuthLayout from "./reusableLayout";
import { Link, useNavigate } from "react-router-dom";

const PasswordReset = ({ isOpen, onClose, setIsMailSent }) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()
 
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return setErrors({ email: "Email is required" });
    }
    setIsSubmitting(true);
    setFeedbackMessage("");

    try {
      const resp = await axiosInstance.post("/reset-pswd-email", { email });
      const { success } = resp.data;
      console.log(resp.data)
      if (success) {
        const maskedEmail = maskEmail(email);
        navigate(`/email-sent/${maskedEmail}`);
      } else {
        setErrors({ email: resp.data.message });
      }
    } catch (error) {
      setFeedbackMessage("An error occurred. Please try again.");
      console.log(error)
    }
    finally {
      setIsSubmitting(false);
    }
  };
  const maskEmail = (email) => {
    const [name, domain] = email.split('@');
    const maskedName = name[0] + '*****' + name.slice(-1); // First character, 5 asterisks, last character
    return `${maskedName}@${domain}`;
  };
  return (
    <div>
        {/* <Link
        to=""
        className="flex p-2 rounded-full bg-gray-1">

        
        </Link>
      
     */}
    <AuthLayout>
      

      <div >

        <h2 className="text-xl font-bold text-center mb-5 text-black-blue">
          Forgot Password
        </h2>

        <p className="text-gray-text text-sm text-center mb-5 max-w-md mx-auto">
          Don’t worry it happen. Please enter the address associated with your account
        </p>

        <form onSubmit={handleSubmit} className="">
          <div className="flex flex-col">
            <label htmlFor="email" className="text-sm text-black-blue mb-3 font-medium">Email</label>
            <TextInput
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors({ email: "" });
              }}
              error={errors.email}
              disabled={isSubmitting}
            />
          </div>
          <div className="flex justify-center items-center w-[50%] mx-auto">
            <button
              type="submit"
              className={`group w-full p-4 border border-transparent text-sm rounded-full text-white bg-custom-blue  focus:outline-none  transition text-[16px] font-medium`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Submit"}
            </button>
          </div>
        </form>

        {feedbackMessage && (
          <p
            className={`mt-4 text-center text-xs font-medium ${feedbackMessage.includes("successfully")
              ? "text-custom-green"
              : "text-custom-red"
              }`}
          >
            {feedbackMessage}
          </p>
        )}

        {/* <p className="mt-5 text-center text-sm text-gray-text">
          Remember your password?{" "}
          <Link
            to='/login'
            className="text-custom-blue hover:underline font-bold focus:outline-none"
          >
            Back to login
          </Link>
        </p> */}
      </div>
    </AuthLayout>
    </div>
  );
};

export default PasswordReset;
